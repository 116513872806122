






























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { COMPANY_TABS } from "@/models/Company";
import CompanyOverview from "./Overview/index.vue";
import CompanyBenefits from "./Benefits/index.vue";
import CompanyDEI from "./DEI/index.vue";
import CompanyActivity from "./Activity/index.vue";
import CompanyService from "@/services/CompanyService";
import SelectDropdownWebsite from "./SelectDropdownWebsite.vue";
import SelectDropdownLocation from "./SelectDropdownLocation.vue";

@Component({
  components: {
    CompanyOverview,
    CompanyBenefits,
    CompanyDEI,
    CompanyActivity,
    SelectDropdownWebsite,
    SelectDropdownLocation
  },
})
export default class CompanyDetail extends Vue {
  public companyTabsEnum: any = COMPANY_TABS;
  public selectedTab: number = this.companyTabsEnum.OVERVIEW;
  public id: any = this.$route.params.companyId;
  public detailData: any = {};
  private loading: boolean = true;
  private limitGroup: any = ''
  private page: any = 1
  public imageDefault = require('@/assets/images/image-default.png')

  // company tabs
  public companyTabs = [
    {
      label: this.$t('company.overview'),
      value: this.companyTabsEnum.OVERVIEW,
    },
    {
      label: this.$t('company.benefit'),
      value: this.companyTabsEnum.BENEFITS,
    },
    {
      label: this.$t('company.dei'),
      value: this.companyTabsEnum.DEI,
    },
    {
      label: this.$t('company.activity'),
      value: this.companyTabsEnum.ACTIVITIES,
    },
  ];

  created() {
    this.getCompanyDetail();
    if (this.$route.params.selectTab) {
      this.selectedTab = this.$route.params.selectTab as any
    }
  }

  getCompanyDetail() {
    CompanyService.getCompanyDetail(this.id)
      .then((res) => {
        if (res.status === 200) {
          this.detailData = res.data;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => this.loading = false);
  }

  // joinLocation() {
  //   console.log(this.detailData.company_area.length);
  //   if (this.detailData.company_area.length) {
  //     return this.detailData.company_area[0].zip_code_province;
  //   } else {
  //     return 'no_data'
  //   }
  // }

  joinIndustry(industryArray: any) {
    return industryArray?.map((item: any) => item.name).join(" • ");
  }
}
