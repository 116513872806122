











































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ModalPreviewImage from '../ModalPreviewImage.vue'
import { listImages } from '../../mockData'

@Component({
  components: {
    ModalPreviewImage
  }
})
export default class CompanyOverview extends Vue {
  @Prop() public title!: string;
  @Prop() detail!: any
  public lstImages: any[] = []
  public selectedImageIndex: number = 0
  public detailCompany: any[] = []
  public idByUrl: any = ''
  public videoError = require('@/assets/images/video-error.png')
  public imageDefault = require('@/assets/images/image-default.png')

  created() {
    this.detailCompany = this.detail
    const formatImages = this.detail.company_images.map((item: any) => {
      return {
        image: item.image,
        type: 'image'
      }
    })
    if (this.detail.url_video) {
      formatImages.unshift({
        image: this.detail.url_video,
        type: 'video'
      })
    }
    this.lstImages = formatImages

    const getIndexOfUrl = this.detail.url_video.lastIndexOf("/", this.detail.url_video);
    this.idByUrl = this.detail.url_video.slice(getIndexOfUrl + 1)

    if (this.idByUrl != '' || this.idByUrl != undefined) {
      return this.idByUrl    
    }  
  }

  @Watch('detail')
  watchDetail() {
    this.detailCompany = this.detail
    const formatImages = this.detail.company_images.map((item: any) => {
      return {
        image: item.image,
        type: 'image'
      }
    })
    if (this.detail.url_video) {
      formatImages.unshift({
        image: this.detail.url_video,
        type: 'video'
      })
    }
    this.lstImages = formatImages
    this.detailCompany = this.detail
  }

  handleShowPreview(selected: string) {
    if (this.lstImages.length) {
      const indexItem = this.lstImages.findIndex(
        (item: string) => item === selected
      )
      this.selectedImageIndex = indexItem
      this.$bvModal.show('modal-preview')
    }
  }

  getId(url: any) {
    const getIndexOfUrl = url.lastIndexOf("/", url);
    this.idByUrl = url.slice(getIndexOfUrl + 1)
    if (this.idByUrl != '' || this.idByUrl != undefined) {
      return this.idByUrl    
    }
  }
}
