






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { benefitTagsList } from '../../mockData'

@Component
export default class CompanyBenefits extends Vue {
  @Prop() title!: any
  @Prop() benefit!: any
  public listBenefitTags: string[] = this.benefit
}
