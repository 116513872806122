













































import PreviewText from "@/helpers/PreviewText";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class SelectDropdownWebsite extends Vue {
  @Prop() public title!: string;
  @Prop() public listOption!: any;

  handleLongText(text: string) {
    return PreviewText.covertToPreviewText(text, 65).text;
  }
}
