
















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Swiper, { Navigation, SwiperOptions } from 'swiper'

@Component
export default class ModalPreviewImage extends Vue {
  @Prop() lstImage!: string[]
  @Prop() selectedIndex!: number
  @Prop() idUrl!: any

  public index: number = this.selectedIndex
  public listImage: any[] = this.lstImage || ''
  public modules: any = [Navigation]
  private id_Url: any = ''
  public videoError = require('@/assets/images/video-error.png')
  public imageDefault = require('@/assets/images/image-default.png')

  created() {
    this.id_Url = this.idUrl
  }

  @Watch('lstImage')
  watchListImage() {
    this.listImage = this.lstImage
  }

  @Watch('selectedIndex')
  watchFirstSelected() {
    this.index = this.selectedIndex
    this.swiperOption = {
      ...this.swiperOption,
      initialSlide: this.selectedIndex
    }
    this.swiperOption2 = {
      ...this.swiperOption2,
      initialSlide: this.selectedIndex
    }
  }

  @Watch('index')
  watchIndexChange() {
    this.swiperOption = {
      ...this.swiperOption,
      initialSlide: this.index
    }
    this.swiperOption2 = {
      ...this.swiperOption2,
      initialSlide: this.index
    }
  }

  public swiperOption: SwiperOptions = { 
    initialSlide: 0,
    loop: false,
    slidesPerView: 1,
    spaceBetween: 10,
    on: {
      slideChange: (swiper: Swiper) => {
        const instance = this.$refs['swiper1'] as any
        const elment = instance.$el.swiper as any
        elment?.slideTo(swiper.activeIndex)
        swiper.on('slideChange', () => {})
      }
    },
    navigation: true
  }

  public swiperOption2: SwiperOptions = {
    initialSlide: 0,
    loop: false,
    slidesPerView: 3.5,
    spaceBetween: 16,
    on: {
      click: (swiper: Swiper) => {
        const instance = this.$refs['swiper'] as any
        const elment = instance.$el.swiper as any
        elment?.slideTo(swiper.clickedIndex)
        swiper.on('click', () => {})
      }
    },
    centeredSlides: true,
    centeredSlidesBounds: true
  }

  handleClick(event: Swiper) {
    this.index = event.clickedIndex
  }

  handleSlideChange(event: Swiper) {
    this.index = event.activeIndex
  }

  getId(url: any) {
    if (url != '' || url != null) {
      const getIndexOfUrl = url.lastIndexOf("/", url);
      const idUrl = url.slice(getIndexOfUrl + 1)
      return idUrl
    }    
  }
}
