


















































import PreviewText from '@/helpers/PreviewText'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class SelectDropdownLocation extends Vue {
  @Prop() public title!: string
  @Prop() public listOption!: any
}
