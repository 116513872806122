































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SelectDropdownLocation from '../SelectDropdownLocation.vue';
import { COMPANY_TABS } from "@/models/Company";
import CompanyService from '@/services/CompanyService';

@Component({
  components: {
    SelectDropdownLocation,
  },
})
export default class CompanyActivity extends Vue {
  @Prop() title!: any
  @Prop() idCompany!: any
  @Prop() value!: any
  public listGroupActivity: string[] = []
  public detailCompany: any[] = this.idCompany
  public limit: any = 9
  public company_id: any = ''
  private loading: boolean = true;
  private totalData: any = ''
  public imageDefault = require('@/assets/images/group/avatar_default_1.png')
  public imageDefault1 = require('@/assets/images/image-default.png')

  created() {
    this.company_id = this.idCompany.id
    this.getGroupByCompanyId()
  }

  getGroupByCompanyId(loadMore?: boolean) {
    const params = {
      company_id: this.company_id,
      limit: this.limit
    }
    CompanyService.getGroupByCompanyId(params)
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.data.map((item: any) => item)
          if (loadMore) {
            this.listGroupActivity = [...this.listGroupActivity, ...data]
          } else {
            this.listGroupActivity = data
          }
          this.totalData = res.data.total
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => this.loading = false);
  }

  loadMore() {
    this.limit = this.limit + 9
    this.getGroupByCompanyId()
  }

  formatNumberEmployee(numberEmployee: any) {
    return new Intl.NumberFormat().format(numberEmployee)
  }

  goToGroupTop(id: any) {
    this.$store.state.auth.token ?
    this.$router.push({
      name: 'group-toppage',
      params: { groupId: id }
    }) :
    this.$router.push({
      name: 'login',
      query: {
        redirect: `group/${id}` as any
      }
    })
  }

  goToTabOverview() {
    this.$emit('goToOverView', COMPANY_TABS.OVERVIEW)
  }
}
