






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { benefitTagsList } from '../../mockData'

@Component
export default class CompanyDEI extends Vue {
  @Prop() dei!: any
  public listDeiTags: string[] = this.dei
}
